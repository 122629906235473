var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "RelatedDamageInputComponent" },
    [
      _c("ui-label", { attrs: { label: "Related damage*" } }),
      _c(
        "div",
        { staticClass: "RelatedDamageInputComponent__input-wrapper" },
        [
          _c("ui-text-input", {
            staticClass: "RelatedDamageInputComponent",
            attrs: {
              value: _setup.valueDisplayed,
              "icon-left": _vm.ICONS.edit,
              "is-readonly": "",
              placeholder: "Select the related damage",
              name: "relatedDamageUuid",
              reverse: "",
              "data-test-id": "related_damage-input",
            },
            on: {
              clickicon: function ($event) {
                return _vm.$emit("on:clickIcon")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }