var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VehicleDamageArea" },
    [
      _c("ui-select", {
        staticClass: "d-block mb-3",
        staticStyle: { "max-width": "250px" },
        attrs: {
          value: _vm.side,
          disabled: _vm.disabled,
          label: "Vehicle side*",
        },
        domProps: { options: _vm.selectOptions },
        on: { selectoption: _vm.onChangeSide },
      }),
      _c("ui-label", {
        staticClass: "mt-0",
        attrs: { disabled: _vm.disabled, label: "Vehicle area highlight*" },
      }),
      _c("span", { staticClass: "emobg-color-ink-light" }, [
        _vm._v(" Click on a vehicle area to highlight the reported damage "),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-wrap mt-3 emobg-background-color-ground-lightest position-relative pb-4",
        },
        [
          _vm.side
            ? _c(
                "DamageCoordinates",
                {
                  staticClass: "d-flex justify-content-center",
                  on: {
                    getCoordinates: (coordinates) =>
                      _vm.saveCoordinates(coordinates, _vm.side),
                  },
                },
                [
                  _c("DamageSide", {
                    class: [
                      "VehicleDamageArea__side",
                      { "VehicleDamageArea__side--disabled": _vm.disabled },
                    ],
                    attrs: {
                      src: _vm.selectedSideBlueprint,
                      label: _vm.selectedSideName,
                      dots: _vm.dots(_vm.side),
                      "image-binds": {
                        style: "max-height:350px;",
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center emobg-font-large emobg-font-weight-bold emobg-color-ink-light",
                  staticStyle: { height: "350px" },
                },
                [_vm._v(" Select a vehicle side ")]
              ),
          _vm.disabled
            ? _c("div", {
                staticClass:
                  "position-absolute emobg-background-color-white w-100 h-100 emobg-cursor-not-allowed",
                staticStyle: { opacity: "0.6" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }