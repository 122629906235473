import { render, staticRenderFns } from "./RelatedDamageInputComponent.vue?vue&type=template&id=07aa377e&"
import script from "./RelatedDamageInputComponent.vue?vue&type=script&setup=true&lang=js&"
export * from "./RelatedDamageInputComponent.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07aa377e')) {
      api.createRecord('07aa377e', component.options)
    } else {
      api.reload('07aa377e', component.options)
    }
    module.hot.accept("./RelatedDamageInputComponent.vue?vue&type=template&id=07aa377e&", function () {
      api.rerender('07aa377e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Carsharing/Vehicles/VehicleDamages/components/RelatedDamageInputComponent.vue"
export default component.exports