<script setup>
import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
} from 'vue';
import { MuiTabs } from '@emobg/vue-base';
import {
  camelCaseKeys,
  filterArray,
  getValue,
  mapObject,
} from '@emobg/web-utils';
import {
  CancelButton,
  GenericModalComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { DAMAGE_BLUEPRINT_SIDE_NAMES, DAMAGES_STATUS } from '../damages.const';
import DamageImageTemplate from './DamageImageTemplate';

const currentInstance = getCurrentInstance();

defineEmits(['update:relatedDamage']);
const props = defineProps({
  vehicleUuid: {
    type: String,
    required: true,
  },
  /**
   * {
   *   uuid,
   *   id,
   *   imageUrl,
   *   relatedDamageUuid,
   * }
   */
  damage: {
    type: Object,
    required: true,
  },
});

const damages = ref([]);
const relatedDamageSelected = ref({
  uuid: getValue(props.damage, 'relatedDamageUuid', null),
});
const isLoading = ref(true);
const showingTabIndex = ref(0);
const sideTabs = computed(() => {
  let counter = 0;
  const tabsWithDamages = mapObject(DAMAGE_BLUEPRINT_SIDE_NAMES, (side, sideName) => {
    counter += 1;
    const filteredDamages = filterArray(damages.value, damage => damage.side === sideName);
    return {
      label: `${side.label}(${filteredDamages.length})`,
      url: { index: counter },
      damages: filteredDamages,
    };
  });
  return [
    {
      label: `All(${damages.value.length})`,
      url: { index: 0 },
      damages: damages.value,
    },
    ...tabsWithDamages,
  ];
});

onMounted(async () => {
  const damageUuid = getValue(props.damage, 'uuid', undefined);
  const algolia = getValue(currentInstance, 'proxy.$algolia');
  const response = await algolia.fetchIndex(ALGOLIA_INDEXES.vehicleDamages, {
    filters: `NOT uuid:${damageUuid} AND vehicle.uuid:${props.vehicleUuid} AND status:${DAMAGES_STATUS.notRepaired}`,
  });
  damages.value = camelCaseKeys(getValue(response, 'hits', []));
  isLoading.value = false;
});

const handleBeforeLeave = (element) => {
  const {
    left,
    top,
  } = element.dataset;

  element.setAttribute('style', `
    left: ${left}px;
    top: ${top}px;
  `);
};
const setPositionDatasets = element => {
  element.dataset.left = element.offsetLeft;
  element.dataset.top = element.offsetTop;
};

const setRelatedDamageSelected = damage => {
  const {
    uuid,
    id,
    side,
    coordinateX,
    coordinateY,
    vehicleArea,
    damageType,
  } = damage;
  relatedDamageSelected.value = {
    uuid,
    id,
    side,
    coordinateX,
    coordinateY,
    vehicleArea,
    damageType,
  };
};

</script>

<template>
  <GenericModalComponent
    :header="{
      title: 'Select the duplicated damage',
      backColor: COLORS.white,
      isClosable: true,
    }"
    :size="SIZES.xLarge"
    class="DuplicatedDamageModal v1-MuiModal--adaptative"
    data-test-id="duplicated_damage-modal"
    v-on="$listeners"
  >
    <template #body>
      <div class="row">
        <div
          class="col-auto"
          style="flex-basis: 300px;"
        >
          <h3 class="mb-3">
            Damage #{{ getValue(damage, 'id', '') }}
          </h3>
          <DamageImageTemplate
            :src="getValue(damage, 'imageUrl', undefined)"
            has-border-radius
            is-overlay-hidden
          />
        </div>
        <div class="col">
          <h3 class="mb-3">
            Not repaired damages
          </h3>
          <MuiTabs
            :tabs="sideTabs"
            class="mb-1"
            @active-tab="({ index }) => showingTabIndex = index"
          />
          <div
            :class="[
              'd-flex flex-column p-3 position-relative',
              'emobg-border-radius-small emobg-border-color-ground emobg-border-1',
              'emobg-background-color-ground-lighter overflow-y-auto',
              {
                'align-items-center justify-content-center': !sideTabs[showingTabIndex].damages.length || isLoading,
              }
            ]"
            style="height: 390px;"
          >
            <ui-loader v-if="isLoading" />
            <template v-else>
              <template v-if="sideTabs[showingTabIndex].damages.length">
                <TransitionGroup
                  name="grid"
                  tag="div"
                  appear
                  class="DuplicatedDamageModal__list d-flex flex-wrap"
                  @before-leave="handleBeforeLeave"
                  @enter="setPositionDatasets"
                >
                  <div
                    v-for="filteredDamage in sideTabs[showingTabIndex].damages"
                    :key="filteredDamage.uuid"
                    class="DuplicatedDamageModal__list-item flex-grow-0 flex-shrink-0 d-flex flex-column align-items-center"
                    @transitionend="setPositionDatasets($event.target)"
                  >
                    <div
                      class="DuplicatedDamageModal__list-item-image-wrapper w-100 mb-2 emobg-cursor-pointer
                    position-relative emobg-border-radius-small overflow-hidden"
                      @click="setRelatedDamageSelected(filteredDamage)"
                    >
                      <div
                        class="DuplicatedDamageModal__list-item-image w-100 h-100 position-absolute"
                        :style="{ backgroundImage: `url(${filteredDamage.imageUrl})` }"
                      />
                      <template v-if="relatedDamageSelected.uuid === filteredDamage.uuid">
                        <div class="position-absolute w-100 h-100 emobg-border-2 emobg-border-color-primary" />
                        <div class="DuplicatedDamageModal__list-item-icon position-absolute">
                          <ui-icon
                            :icon="ICONS.bold.check"
                            :color="COLORS.primary"
                            class="position-relative"
                          />
                        </div>
                      </template>
                    </div>
                    <RouterLink
                      :to="{
                        name: fleet.damages.edit,
                        params: {
                          damageUuid: getValue(filteredDamage, 'uuid', null),
                        },
                      }"
                      target="_blank"
                      class="d-block link emobg-font-weight-semibold"
                      data-test-id="vehicle_details-link"
                    >
                      #{{ filteredDamage.id }}
                    </RouterLink>
                  </div>
                </TransitionGroup>
              </template>
              <h2
                v-else
                class="emobg-color-ink-light"
              >
                No damages reported
              </h2>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <ui-button
        :disabled="!relatedDamageSelected.uuid || relatedDamageSelected.uuid === getValue(damage, 'relatedDamageUuid', null)"
        class="wmin-initial"
        @clickbutton="$emit('update:relatedDamage', relatedDamageSelected)"
      >
        Assign duplicated
      </ui-button>
    </template>
  </GenericModalComponent>
</template>

<style lang="scss">
.DuplicatedDamageModal {
  &__list {
    gap: 8px 20px;

    &-item {
      width: 118px;

      &-image-wrapper {
        height: 158px;
      }

      &-image {
        background-size: cover;
      }

      &-icon {
        right: 2px;
        bottom: 2px;

        &::before {
          position: absolute;
          top: 1px;
          left: 2px;
          display: block;
          width: 16px;
          height: 16px;
          content: "";
          background: #fff;
          border-radius: 50%;
        }
      }
    }

    .grid {
      &-enter {
        opacity: 0;
      }

      &-enter-active {
        transition: all 1s;
      }

      &-leave-active {
        position: absolute;
        opacity: 0;
        transition: all 0.3s;
      }

      &-move {
        transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }
  }
}
</style>
