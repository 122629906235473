<script setup>
import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from 'vue';
import { getValue } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

const emit = defineEmits([
  'on:clickIcon',
  'update:modelValue',
]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});
const currentInstance = getCurrentInstance();
const algolia = getValue(currentInstance, 'proxy.$algolia');
const relatedDamageUuid = ref(null);
const relatedDamageId = ref(null);
const setInternalValues = () => {
  relatedDamageUuid.value = getValue(props.modelValue, 'uuid', null);
  relatedDamageId.value = getValue(props.modelValue, 'id', null);
};
setInternalValues();
const valueDisplayed = computed(() => (
  relatedDamageId.value ? `#${relatedDamageId.value}` : relatedDamageUuid.value
));

watch(() => props.modelValue, () => {
  setInternalValues();
  emit('update:modelValue', props.modelValue);
});

onMounted(async () => {
  if (relatedDamageUuid.value && !relatedDamageId.value) {
    const response = await algolia.fetchIndex(
      ALGOLIA_INDEXES.vehicleDamages,
      { filters: `uuid:${relatedDamageUuid.value}` },
    );
    relatedDamageId.value = getValue(response, 'hits[0].id', null);
  }
});
</script>
<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
};
</script>

<template>
  <div class="RelatedDamageInputComponent">
    <ui-label label="Related damage*" />
    <div class="RelatedDamageInputComponent__input-wrapper">
      <ui-text-input
        :value="valueDisplayed"
        :icon-left="ICONS.edit"
        is-readonly
        placeholder="Select the related damage"
        name="relatedDamageUuid"
        reverse
        class="RelatedDamageInputComponent"
        data-test-id="related_damage-input"
        @clickicon="$emit('on:clickIcon')"
      />
    </div>
  </div>
</template>
