var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.GenericModalComponent,
    _vm._g(
      {
        staticClass: "DuplicatedDamageModal v1-MuiModal--adaptative",
        attrs: {
          header: {
            title: "Select the duplicated damage",
            backColor: _vm.COLORS.white,
            isClosable: true,
          },
          size: _vm.SIZES.xLarge,
          "data-test-id": "duplicated_damage-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-auto",
                      staticStyle: { "flex-basis": "300px" },
                    },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(
                          " Damage #" +
                            _vm._s(_setup.getValue(_vm.damage, "id", "")) +
                            " "
                        ),
                      ]),
                      _c(_setup.DamageImageTemplate, {
                        attrs: {
                          src: _setup.getValue(
                            _vm.damage,
                            "imageUrl",
                            undefined
                          ),
                          "has-border-radius": "",
                          "is-overlay-hidden": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(" Not repaired damages "),
                      ]),
                      _c(_setup.MuiTabs, {
                        staticClass: "mb-1",
                        attrs: { tabs: _setup.sideTabs },
                        on: {
                          "active-tab": ({ index }) =>
                            (_setup.showingTabIndex = index),
                        },
                      }),
                      _c(
                        "div",
                        {
                          class: [
                            "d-flex flex-column p-3 position-relative",
                            "emobg-border-radius-small emobg-border-color-ground emobg-border-1",
                            "emobg-background-color-ground-lighter overflow-y-auto",
                            {
                              "align-items-center justify-content-center":
                                !_setup.sideTabs[_setup.showingTabIndex].damages
                                  .length || _setup.isLoading,
                            },
                          ],
                          staticStyle: { height: "390px" },
                        },
                        [
                          _setup.isLoading
                            ? _c("ui-loader")
                            : [
                                _setup.sideTabs[_setup.showingTabIndex].damages
                                  .length
                                  ? [
                                      _c(
                                        "TransitionGroup",
                                        {
                                          staticClass:
                                            "DuplicatedDamageModal__list d-flex flex-wrap",
                                          attrs: {
                                            name: "grid",
                                            tag: "div",
                                            appear: "",
                                          },
                                          on: {
                                            "before-leave":
                                              _setup.handleBeforeLeave,
                                            enter: _setup.setPositionDatasets,
                                          },
                                        },
                                        _vm._l(
                                          _setup.sideTabs[
                                            _setup.showingTabIndex
                                          ].damages,
                                          function (filteredDamage) {
                                            return _c(
                                              "div",
                                              {
                                                key: filteredDamage.uuid,
                                                staticClass:
                                                  "DuplicatedDamageModal__list-item flex-grow-0 flex-shrink-0 d-flex flex-column align-items-center",
                                                on: {
                                                  transitionend: function (
                                                    $event
                                                  ) {
                                                    return _setup.setPositionDatasets(
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "DuplicatedDamageModal__list-item-image-wrapper w-100 mb-2 emobg-cursor-pointer position-relative emobg-border-radius-small overflow-hidden",
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.setRelatedDamageSelected(
                                                          filteredDamage
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "DuplicatedDamageModal__list-item-image w-100 h-100 position-absolute",
                                                      style: {
                                                        backgroundImage: `url(${filteredDamage.imageUrl})`,
                                                      },
                                                    }),
                                                    _setup.relatedDamageSelected
                                                      .uuid ===
                                                    filteredDamage.uuid
                                                      ? [
                                                          _c("div", {
                                                            staticClass:
                                                              "position-absolute w-100 h-100 emobg-border-2 emobg-border-color-primary",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "DuplicatedDamageModal__list-item-icon position-absolute",
                                                            },
                                                            [
                                                              _c("ui-icon", {
                                                                staticClass:
                                                                  "position-relative",
                                                                attrs: {
                                                                  icon: _vm
                                                                    .ICONS.bold
                                                                    .check,
                                                                  color:
                                                                    _vm.COLORS
                                                                      .primary,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "RouterLink",
                                                  {
                                                    staticClass:
                                                      "d-block link emobg-font-weight-semibold",
                                                    attrs: {
                                                      to: {
                                                        name: _setup.fleet
                                                          .damages.edit,
                                                        params: {
                                                          damageUuid:
                                                            _setup.getValue(
                                                              filteredDamage,
                                                              "uuid",
                                                              null
                                                            ),
                                                        },
                                                      },
                                                      target: "_blank",
                                                      "data-test-id":
                                                        "vehicle_details-link",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " #" +
                                                        _vm._s(
                                                          filteredDamage.id
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  : _c(
                                      "h2",
                                      { staticClass: "emobg-color-ink-light" },
                                      [_vm._v(" No damages reported ")]
                                    ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(_setup.CancelButton, {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled:
                        !_setup.relatedDamageSelected.uuid ||
                        _setup.relatedDamageSelected.uuid ===
                          _setup.getValue(
                            _vm.damage,
                            "relatedDamageUuid",
                            null
                          ),
                    },
                    on: {
                      clickbutton: function ($event) {
                        return _vm.$emit(
                          "update:relatedDamage",
                          _setup.relatedDamageSelected
                        )
                      },
                    },
                  },
                  [_vm._v(" Assign duplicated ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }