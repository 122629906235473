<script>
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { FALLBACK_MESSAGE, getValue } from '@emobg/web-utils';
import DamageCoordinates from './DamageCoordinates';
import DamageSide from '../DamageSide';
import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../damages.const';

export default {
  name: 'VehicleDamageArea',
  components: {
    DamageCoordinates,
    DamageSide,
  },
  model: {
    prop: 'damagePoint',
    event: 'change',
  },
  props: {
    damagePoint: {
      type: Object,
      default: null,
    },
    sides: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      side: null,
      isSideLoaded: false,
    };
  },
  computed: {
    selectOptions() {
      const sortedSides = sortBy(this.sides, damage => getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${damage.side}.position`, 0));
      return map(sortedSides, ({ side }) => ({ label: DAMAGE_BLUEPRINT_SIDE_NAMES[side].label, value: side }));
    },
    selectedSideBlueprint() {
      return this.side ? getValue(find(this.sides, ['side', this.side]), 'url', null) : null;
    },
    selectedSideName() {
      return this.side ? DAMAGE_BLUEPRINT_SIDE_NAMES[this.side].label : FALLBACK_MESSAGE.dash;
    },
  },
  watch: {
    damagePoint: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.side = getValue(this.damagePoint, 'side', '');
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeSide({ detail }) {
      this.$emit('change', {
        side: detail,
        coordinateX: null,
        coordinateY: null,
      });
    },
    saveCoordinates(coordinates, side) {
      this.$emit('change', {
        side,
        coordinateX: (coordinates.x * 100) / coordinates.target.width,
        coordinateY: (coordinates.y * 100) / coordinates.target.height,
      });
    },
    dots(side) {
      const { side: damageSide, coordinateY, coordinateX } = this.damagePoint;
      return damageSide === side && coordinateY && coordinateX
        ? [{ x: coordinateX, y: coordinateY }]
        : [];
    },
  },
};
</script>
<template>
  <div class="VehicleDamageArea">
    <ui-select
      :value="side"
      :options.prop="selectOptions"
      :disabled="disabled"
      label="Vehicle side*"
      class="d-block mb-3"
      style="max-width: 250px;"
      @selectoption="onChangeSide"
    />
    <ui-label
      :disabled="disabled"
      label="Vehicle area highlight*"
      class="mt-0"
    />
    <span class="emobg-color-ink-light">
      Click on a vehicle area to highlight the reported damage
    </span>

    <div class="d-flex flex-column flex-wrap mt-3 emobg-background-color-ground-lightest position-relative pb-4">
      <DamageCoordinates
        v-if="side"
        class="d-flex justify-content-center"
        @getCoordinates="coordinates => saveCoordinates(coordinates, side)"
      >
        <DamageSide
          :src="selectedSideBlueprint"
          :label="selectedSideName"
          :dots="dots(side)"
          :image-binds="{
            style: 'max-height:350px;',
          }"
          :class="['VehicleDamageArea__side', { 'VehicleDamageArea__side--disabled': disabled }]"
        />
      </DamageCoordinates>
      <div
        v-else
        class="d-flex justify-content-center align-items-center emobg-font-large emobg-font-weight-bold emobg-color-ink-light"
        style="height: 350px;"
      >
        Select a vehicle side
      </div>
      <div
        v-if="disabled"
        class="position-absolute emobg-background-color-white w-100 h-100 emobg-cursor-not-allowed"
        style="opacity: 0.6;"
      />
    </div>
  </div>
</template>

<style lang="scss">
.VehicleDamageArea {
  &__side {
    label {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--disabled {
      .DamageDot {
        background-color: #000;
        outline: 10px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>

